<template>
  <div>
    <div class="cookies-modal__title">
      <span>{{ $t('cookies.about_cookies_politics') }}</span>
    </div>
    <div class="cookies-modal__wrapper">
      <div class="cookies-modal__paragraph">
        <span>{{ $t('cookies.info_politics_message_1') }}</span>
        <span>
          {{ $t('cookies.info_politics_message_2') }}
          <a
            style="cursor: pointer"
            :title="$t('cookies.info_politics_link')"
            @click.stop="handleCookiesPolicyText">
            {{ $t('cookies.info_politics_link') }}
          </a>
        </span>
        <span>{{ $t('cookies.info_politics_message_3') }}</span>
        <span class="highlight">{{ $t('cookies.info_politics_message_4') }}</span>
      </div>

      <div class="cookies-modal__buttons">
        <button
          class="buttons cookies-modal__buttons-modify"
          @click="handleModifyCookies">
          {{ modifyText }}
        </button>
        <button
          class="buttons cookies-modal__buttons-accept"
          @click="handleAcceptAllCookies">
          {{ $t('cookies.cookies_politics.accept') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CookiesPolicy',
  };
</script>

<script setup>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  const props = defineProps({
    isDesktopDevice: {
      type: Boolean,
      required: true,
    },
  });
  const emit = defineEmits(['modifyCookies', 'acceptAllCookies', 'showCookiesPolicyText']);

  const modifyText = computed(() => {
    return props.isDesktopDevice ? t('cookies.cookies_politics.modify_desktop') : t('cookies.cookies_politics.modify');
  });

  const handleModifyCookies = () => {
    emit('modifyCookies');
  };
  const handleAcceptAllCookies = () => {
    emit('acceptAllCookies');
  };
  const handleCookiesPolicyText = () => {
    emit('showCookiesPolicyText');
  };
</script>
