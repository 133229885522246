<template>
  <div>
    <div class="cookies-modal__wrapper column">
      <span class="cookies-modal__title">{{
        $t('cookies.modal_policy.politica')
      }}</span>
      <div class="cookies-modal__paragraph">
        <span>{{ $t('cookies.modal_policy.text_1') }}</span>
        <span
          >{{ $t('cookies.modal_policy.text_2') }}
          <a :href="'mailto:' + mailToLink" :title="mailToLink">{{
            mailToLink
          }}</a></span
        >
        <span>{{ $t('cookies.modal_policy.text_4') }}</span>
        <span>{{ $t('cookies.modal_policy.text_5') }}</span>
        <span>{{ $t('cookies.modal_policy.text_6') }}</span>
        <span>{{ $t('cookies.modal_policy.text_7') }}</span>

        <span>
          <a
            :href="cookiesPolicyLink"
            :title="$t('cookies.modal_policy.ver_politica')"
            target="_blank"
            >{{ $t('cookies.modal_policy.ver_politica') }}</a
          >
        </span>
      </div>

      <div class="cookies-modal__buttons centered">
        <button
          class="buttons cookies-modal__buttons-accept"
          @click="AcceptClick"
        >
          {{ $t('cookies.cookies_politics.accept') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiesModalPolicyText',
};
</script>

<script setup>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const mailToLink =
  t('cookies.modal_policy.text_3_dir') +
  '@' +
  t('cookies.modal_policy.text_3_dom');

const store = useStore();
const siteLinks = store.getters['page/getSiteLinks'];
const cookiesPolicyLink = siteLinks.cookies_policy + '?modal=1';

const emit = defineEmits(['onAcceptClick']);
const AcceptClick = () => {
  emit('onAcceptClick');
};
</script>

<style lang="less" scoped>
.centered {
    flex-direction: row;
    justify-content: center;
}
</style>