<template>
  <div :class="{ 'disable-slide': disable }">
    <label class="switch" :for="idName">
      <input
        type="checkbox"
        :id="idName"
        :checked="slideValue"
        @change="handleSlide"
      />
      <div class="slider-checkbox round"></div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'SlideCheckBox',
  props: {
    defaultValue: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    idName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      slideValue: false,
    };
  },
  methods: {
    handleSlide() {
      this.slideValue = !this.slideValue;
      this.$emit('slide', this.slideValue);
    },
  },
  mounted() {
    this.slideValue = this.defaultValue;
  },
};
</script>

<style lang="less" scoped>
.disable-slide {
  pointer-events: none;
}
</style>