<template>
  <template v-if="isClientMounted">
    <Teleport to="#Layout-modal-newsletter-designers">
      <Modal
        v-show="iframLoaded && !showPrivacy && !responseMessage"
        v-if="showIframe"
        name="designers_newsletter_modal"
        :show-close-button="true"
        transition-class="slide-fade"
        :main-class="`${MODAL_TYPES.SCREEN_CENTERED_NEWS_DESIGNERS}`"
        @close="closePopup">
        <template #body>
          <iframe
            ref="designersIframeRef"
            frameborder="0"
            :src="iframeUrl"
            @load="onIframeLoad"></iframe>
          <Spinner
            v-if="responseIsLoading"
            container-class="-center" />
        </template>
        <template #footer>
          <form class="newsletter-designers__form">
            <div class="newsletter-designers__privacy">
              <input
                id="designres-privacy"
                v-model="formPrivacy"
                type="checkbox"
                @click.stop="!formPrivacy && showPrivacyModal()" />
              <label for="designres-privacy">{{ $t('footer.newsletter.designers.privacy.label') }}
                <a
                  href="javascript:void(0)"
                  @click="showPrivacyModal">{{ $t('footer.newsletter.designers.privacy.link') }}</a>.
              </label>
            </div>

            <div class="newsletter-designers__input">
              <input
                v-model.lazy="formEmail"
                type="email"
                :placeholder="$t('global.email')" />
              <button @click.prevent="submitForm">
                {{ $t('layouts.footer.newsletter.button') }}
              </button>
              <p
                v-if="!isValidForm"
                class="newsletter-designers__error">
                {{ formError }}
              </p>
            </div>
          </form>
        </template>
      </Modal>

      <Modal
        v-if="showIframe && responseMessage"
        name="designers_message_modal"
        :show-close-button="true"
        transition-class="slide-fade"
        :main-class="`newsletter-designers__message-modal ${MODAL_TYPES.SCREEN_CENTERED}`"
        @close="closeModals">
        <template #body>
          <div class="newsletter__modal-message">
            {{ responseMessage }}
          </div>
        </template>
        <template #footer>
          <button
            class="newsletter-designers__message-button"
            @click="closeModals">
            {{ $t('client.components.modals.base.aceptar') }}
          </button>
        </template>
      </Modal>
    </Teleport>

    <Teleport to="#Layout-modal-newsletter-feedback">
      <Modal
        v-if="showPrivacy"
        name="designers_privacy_modal"
        :show-close-button="true"
        transition-class="slide-fade"
        :main-class="`newsletter-designers__privacy-modal ${MODAL_TYPES.SCREEN_CENTERED_BIG}`"
        @close="closePrivacyModal">
        <template #body>
          <Spinner
            v-if="!privacyLoaded"
            container-class="-center" />
          <iframe
            v-show="privacyLoaded"
            :src="privacyUrl"
            frameborder="0"
            @load="onPrivacyLoad"></iframe>
        </template>
        <template #footer>
          <button
            class="newsletter-designers__privacy-button"
            @click="closePrivacyModal">
            {{ $t('client.components.modals.base.aceptar') }}
          </button>
        </template>
      </Modal>
    </Teleport>
  </template>
</template>

<script>
  import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';
  import Modal from 'CommonComponents/Modal/Modal.vue';
  import Spinner from 'CommonComponents/UI/Spinner.vue';
  import MODAL_TYPES from 'Utils/modalTypes';
  import useDesignersForm from './useDesignersForm';
  import useDesignersPopup from './useDesignersPopup';
  import useDesignersPrivacy from './useDesignersPrivacy';

  export default {
    name: 'DesignersPopup',
    components: { Modal, Spinner },
    emits: ['designersPopupClosed'],
    setup(_, context) {
      const { isClientMounted } = useNavigatorDetect();
      const {
        formPrivacy,
        formEmail,
        formError,
        responseMessage,
        responseIsLoading,
        isValidForm,
        submitForm,
        closeMessageModal,
      } = useDesignersForm();
      const {
        iframeUrl,
        iframLoaded,
        showIframe,
        designersIframeRef,
        onIframeLoad,
        closePopup
      } = useDesignersPopup(context);
      const {
        privacyUrl,
        privacyLoaded,
        showPrivacy,
        onPrivacyLoad,
        closePrivacyModal,
        showPrivacyModal
      } = useDesignersPrivacy();

      const closeModals = () => {
        closeMessageModal();
        closePopup();
      }

      return {
        MODAL_TYPES,
        isClientMounted,
        formPrivacy,
        formEmail,
        formError,
        isValidForm,
        responseMessage,
        responseIsLoading,
        submitForm,
        closeModals,
        iframeUrl,
        iframLoaded,
        showIframe,
        designersIframeRef,
        onIframeLoad,
        closePopup,
        privacyUrl,
        privacyLoaded,
        showPrivacy,
        onPrivacyLoad,
        closePrivacyModal,
        showPrivacyModal
      };
    },
  };
</script>

<style scoped></style>
