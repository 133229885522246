import { ref } from 'vue';
import { useStore } from 'vuex';

const useDesignersPrivacy = () => {
  const store = useStore();
  const privacyUrl = store.getters['newsletter/getPrivacyLink'];

  const showPrivacy = ref(false);
  const privacyLoaded = ref(false);

  const closePrivacyModal = () => {
    showPrivacy.value = false;
    privacyLoaded.value = false;
  };

  const showPrivacyModal = () => {
    showPrivacy.value = true;
  };

  const onPrivacyLoad = () => {
    privacyLoaded.value = true;
  };

  return {
    privacyUrl,
    privacyLoaded,
    showPrivacy,
    onPrivacyLoad,
    closePrivacyModal,
    showPrivacyModal,
  };
};

export default useDesignersPrivacy;
