<template>
  <div>
    <div class="cookies-wrapper-column">
      <div class="cookies-modal">
        <CookiesPolicy
          @modifyCookies="handleModifyCookies"
          @acceptAllCookies="handleAcceptAllCookies"
          @showCookiesPolicyText="handleShowCookiesPolicyText"
          :isDesktopDevice="isDesktopDevice"
          v-if="!showCookiesSelector && !showCookiesPolicyText"
        />
      </div>
    </div>
    <Modal
      v-if="showCookiesSelector || showCookiesPolicyText"
      :showCloseButton="true"
      mainClass="floating-modal"
      @close="closeModal"
    >
      <template v-slot:body>
        <CookiesModify
          v-if="showCookiesSelector && !showCookiesPolicyText"
          :isDesktopDevice="isDesktopDevice"
          @acceptAllCookies="handleAcceptAllCookies"
          @saveCookies="handleSaveCookies"
        />
        <CookiesModalPolicyText
          v-if="!showCookiesSelector && showCookiesPolicyText"
          @onAcceptClick="closeModal"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'CookiesModal',
};
</script>

<script setup>
import { ref } from 'vue';
import CookiesPolicy from './CookiesModal/CookiesPolicy.vue';
import CookiesModify from './CookiesModal/CookiesModify.vue';
import CookiesModalPolicyText from './CookiesModal/CookiesModalPolicyText.vue';
import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';
import UtilsAnalytics from 'CommonUtils/analytics.js';
import Modal from 'CommonComponents/Modal/Modal.vue';

const emit = defineEmits(['acceptCookies']);
const { isDesktopDevice } = useNavigatorDetect();

const showCookiesSelector = ref(false);
const showCookiesPolicyText = ref(false);

const handleAcceptAllCookies = () => {
  emit('acceptCookies', [1, 1, 1, 1, 1]);
  UtilsAnalytics.sendReloadEvent();
};
const handleModifyCookies = () => {
  showCookiesSelector.value = true;
};
const handleSaveCookies = (cookieArrayValues) => {
  emit('acceptCookies', cookieArrayValues);
  UtilsAnalytics.sendReloadEvent();
};
const handleShowCookiesPolicyText = () => {
  showCookiesPolicyText.value = true;
};
const closeModal = () => {
  showCookiesSelector.value = false;
  showCookiesPolicyText.value = false;
};
</script>