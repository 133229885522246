<template>
  <div class="cookies-modify__wrapper">
    <div class="cookies-modify__body">
      <div class="cookies-modify__modal-title cookies-modify__pl">
        <span>{{ $t('cookies.modify.title') }}</span>
      </div>

      <div class="cookies-modal__items-wrapper">
        <CookiesItemSelector
          :title="$t('cookies.modify.cookie_technical')"
          :defaultValue="true"
          :disable="true"
          idName="cookie_technical"
          :showInfoIcon="!isDesktopDevice"
          :text="$t('cookies.modify.cookie_technical_text')"
        />
        <CookiesItemSelector
          :title="$t('cookies.modify.cookie_analitycs')"
          :text="$t('cookies.modify.cookie_analitycs_text')"
          idName="cookie_analitycs"
          :showInfoIcon="!isDesktopDevice"
          @selectItem="handleAnalitycs"
        />
        <CookiesItemSelector
          :title="$t('cookies.modify.cookie_custom')"
          :text="$t('cookies.modify.cookie_custom_text')"
          idName="cookie_custom"
          :showInfoIcon="!isDesktopDevice"
          @selectItem="handleCustom"
        />
        <CookiesItemSelector
          :title="$t('cookies.modify.cookie_adds')"
          :text="$t('cookies.modify.cookie_adds_text')"
          idName="cookie_adds"
          :showInfoIcon="!isDesktopDevice"
          @selectItem="handleAdds"
        />
        <CookiesItemSelector
          :title="$t('cookies.modify.cookie_assessment')"
          :text="$t('cookies.modify.cookie_assessment_text')"
          idName="cookie_assessment"
          :showInfoIcon="!isDesktopDevice"
          @selectItem="handleAssessment"
        />
      </div>
      <div class="cookies-modify__hiperlink">
      <a :href="siteLinks.cookies_policy + '?modal=1'" target="_blank">{{ $t('cookies.modify.more_info') }}</a>
    </div>
    </div>
    
    <div class="cookies-modal__buttons modify">
      <button
        class="buttons cookies-modal__buttons-modify"
        @click="handleSaveCookies"
      >
        {{ saveText }}
      </button>
      <button
        class="buttons cookies-modal__buttons-accept"
        @click="handleAcceptAllCookies"
      >
        {{ acceptText }}
      </button>
    </div>
  </div>
</template>

<script>
import CookiesItemSelector from './CookiesItemSelector.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CookiesModify',

  data() {
    return {
      cookiesArrayValues: [1, 0, 0, 0, 0],
    };
  },
  props: {
    isDesktopDevice: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
      ...mapGetters('page', {
        siteLinks: 'getSiteLinks',
      }),
    acceptText() {
      return this.isDesktopDevice
        ? this.$t('cookies.modify.accept_desktop')
        : this.$t('cookies.modify.accept');
    },
    saveText() {
      return this.isDesktopDevice
        ? this.$t('cookies.modify.save_desktop')
        : this.$t('cookies.modify.save');
    },
  },
  methods: {
    handleAnalitycs(value) {
      this.cookiesArrayValues[1] = value ? 1 : 0;
    },
    handleCustom(value) {
      this.cookiesArrayValues[2] = value ? 1 : 0;
    },
    handleAdds(value) {
      this.cookiesArrayValues[3] = value ? 1 : 0;
    },
    handleAssessment(value) {
      this.cookiesArrayValues[4] = value ? 1 : 0;
    },
    handleSaveCookies() {
      this.$emit('saveCookies', this.cookiesArrayValues);
    },
    handleAcceptAllCookies() {
      this.$emit('acceptAllCookies');
    },
  },
  components: {
    CookiesItemSelector,
  },
  mounted() {
    if (!this.isDesktopDevice) document.body.style.overflowY = 'hidden';
  },
  beforeUnmount() {
    if (!this.isDesktopDevice) document.body.style.overflowY = 'auto';
  },
};
</script>
