<template>
  <div class="cookies-modify">
    <div class="cookies-modify__line">
      <div v-if="showInfoIcon">
        <i class="cookies-modify__info-icon" @click="handleIconClick"></i>
      </div>
      <div class="cookies-modify__title" @click="handleIconClick">
        <span>{{ title }}</span>
      </div>
      <SlideCheckBox
        :defaultValue="defaultValue"
        :idName="idName"
        :disable="disable"
        @slide="handleSlideClick"
      />
    </div>

    <div v-if="showInfo || !showInfoIcon" class="cookies-modify__text">
      {{ text }}
    </div>
  </div>
</template>

<script>
import SlideCheckBox from 'CommonComponents/Slide/SlideCheckBox.vue';
export default {
  name: 'CookiesItemSelector',
  data() {
    return {
      showInfo: false,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    idName: {
      type: String,
      required: true,
    },
    showInfoIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSlideClick(value) {
      this.$emit('selectItem', value);
    },
    handleIconClick() {
      this.showInfo = !this.showInfo;
    },
  },
  components: {
    SlideCheckBox,
  }
};
</script>

<style lang="less" scoped>
</style>