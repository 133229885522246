import { onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';

const useDesignersPopup = (context) => {
  const store = useStore();
  const layoutOptions = store.getters['page/getLayoutOptions'];
  const { designers_newsletter_pag } = layoutOptions ?? {};
  const isDesigners = store.getters['page/isDesigners'];
  const iframeUrl = `/${designers_newsletter_pag}?modal=1`;

  const popupId = 'designerspopup_ttl';
  const iframeMainClass = 'newsletter-subscription-designers';

  const showIframe = ref(false);
  const iframLoaded = ref(false);

  const closePopup = () => {
    showIframe.value = false;
    iframLoaded.value = false;
    designersIframeRef.value = null;
    context.emit('designersPopupClosed');
  };

  const showPopup = () => {
    showIframe.value = true;
    localStorage.setItem(popupId, Date.now());
  };

  const shouldShowPopup = () => {
    if (!isDesigners || !designers_newsletter_pag) return;

    const storedDate = localStorage.getItem(popupId);
    if (!storedDate) return true;

    const currentDate = Date.now();
    const difference = currentDate - parseInt(storedDate);
    const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25; // Aproximadamente, considerando años bisiestos
    const yearsPassed = difference / millisecondsPerYear;
    return yearsPassed >= 1;
  };

  onMounted(() => {
    if (!shouldShowPopup()) return;
    showPopup();
  });

  const designersIframeRef = ref(null);
  let resizeObserver;

  const designersIframeResizeHandler = () => {
    if (!designersIframeRef.value) return;
    const iframeHeight = designersIframeRef.value.contentWindow.document.body.scrollHeight;
    designersIframeRef.value.style.height = `calc(${iframeHeight}px - 6px)`;
    designersIframeRef.value.contentWindow.document.body.style.overflowY = 'hidden';
  };

  const iframeResizeObserve = () => {
    resizeObserver = new ResizeObserver(designersIframeResizeHandler);
    resizeObserver.observe(designersIframeRef.value);
  };

  const onIframeLoad = () => {
    designersIframeRef.value.contentWindow.document.body.querySelector('.main__content').classList.add(iframeMainClass);
    iframeResizeObserve();
    iframLoaded.value = true;
  };

  onUnmounted(() => {
    if (!designersIframeRef.value) return;
    resizeObserver.unobserve(designersIframeRef.value);
  });

  return {
    iframeUrl,
    iframLoaded,
    showIframe,
    designersIframeRef,
    onIframeLoad,
    closePopup,
  };
};

export default useDesignersPopup;
