import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import Validators from 'CommonUtils/validator';

const useDesignersForm = () => {
  const store = useStore();
  const { t } = useI18n();
  const currentUser = computed(() => store.getters['cart/getUser']);
  const responseMessage = computed(() => store.getters['newsletter/getMessage']);
  const responseIsLoading = computed(() => store.getters['newsletter/isLoading']);
  const submitNewsletter = (email) => store.dispatch('newsletter/postNewsletterDesignersSub', email);
  const closeMessageModal = () => store.dispatch('newsletter/clearNewsletterDesignersResponse');

  const formPrivacy = ref(false);
  const formEmail = ref('');
  const formError = ref('');
  const isValidForm = computed(() => !formError.value);

  const validateForm = () => {
    if (!formPrivacy.value) return (formError.value = t('footer.newsletter.designers.errors.privacy'));
    if (!Validators.isValidEmail(formEmail.value)) return (formError.value = t('footer.newsletter.designers.errors.email'));
    formError.value = '';
  };

  const submitForm = () => {
    validateForm();
    if (!isValidForm.value) return;
    submitNewsletter(formEmail.value);
  };

  watchEffect(() => {
    const email = currentUser.value?.email;
    if (!email) return;
    formEmail.value = email;
  });
  
  return {
    formPrivacy,
    formEmail,
    formError,
    responseMessage,
    responseIsLoading,
    isValidForm,
    submitForm,
    closeMessageModal,
  };
};

export default useDesignersForm;
