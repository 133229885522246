<template>
  <CookiesModal
    v-if="canShowCookiesModal"
    @acceptCookies="handleCookies" />
  <DesignersPopup
    v-if="canShowDesignersModal"
    @designers-popup-closed="handleDesigners" />
</template>

<script>
  export default {
    name: 'InitialAlerts',
    emits: ['cookiesAccepted'],
  };
</script>

<script setup>
  import CookiesModal from 'CommonComponents/InitialAlerts/CookiesModal.vue';
  import DesignersPopup from 'CommonComponents/InitialAlerts/Designers/DesignersPopup.vue';
  import UtilsCookies from 'CommonUtils/cookies.js';
  import { mapGetter, mapAction } from 'CommonUtils/store/state.js';
  import ScriptUtils from 'CommonUtils/addScript';
  import UtilsAnalytics from 'CommonUtils/analytics.js';
  import UtilsPlacements from 'CommonUtils/placements.js';
  import { inject, ref, onMounted, computed, onUnmounted } from 'vue';

  const emit = defineEmits();
  const MODALS = Object.freeze({
    COOKIES_POLICY: 'cookiesPolicy',
    DESIGNERS_POPUP: 'designersPopup',
  });
  
  const hasBazaarVoice  = inject('hasBazaarVoice', false);
  const bazaarVoiceSEO  = inject('bazaarVoiceSEO', false);
  const isModal         = mapGetter('page/isModal');
  const internals       = mapGetter('page/getInternals').value;
  const placements      = mapGetter('page/getPlacements');
  const setGDPRCookie   = mapAction('page', 'setGDPRCookie');

  const DEFAULT_SEGMENT         = 'aa993fbb-6ef3-483f-8e79-00f8b1bc3b16';
  const hasQueryNoCookiesModal  = internals?.req?.query?.nockmodal; 
  const orderModalsToShow       = ref([]);

  const groupedPlacements = computed(() => {
    if(!placements.value) return;
    let compos = [];
    Object.values(placements.value).forEach(({ compositions }) => {
      if(Array.isArray(compositions)) {
        compos =  [...compos, ...compositions];
      } else {
        compos.push(compositions);
      }
    });
    return compos;
  });

  const canShowCookiesModal = computed(() => {
    const [firstModal] = orderModalsToShow.value;
    const isCookiesModalVisible = !isModal.value && firstModal === MODALS.COOKIES_POLICY;
    if(isCookiesModalVisible){
      window.localStorage.setItem('kxelcorteinglessa_segs', DEFAULT_SEGMENT);
      UtilsPlacements.loadCDPfunctions(groupedPlacements.value);
    }
    return false;
    return isCookiesModalVisible;
  });

  const canShowDesignersModal = computed(() => {
    const [firstModal] = orderModalsToShow.value;
    return !isModal.value && firstModal === MODALS.DESIGNERS_POPUP;
  });

  const getDomain = () => {
    const host = document.location.host;
    if (host.startsWith('localhost') || host.startsWith('192.168')) return document.location.hostname;
    return document.location.host
      .split('.')
      .reverse()
      .reduce(function (a, b, c) {
        if (c === 1) a = '.' + b + '.' + a;
        return a.split(':')[0];
      });
  };

  const removeModal = () => orderModalsToShow.value.shift();

  const addModal = (modalName) => orderModalsToShow.value.push(modalName);

  const handleCookies = (cookiesData) => {
    const cookieVal = cookiesData.join('');
    const domain = getDomain();
    const path = '/';
    UtilsCookies.setCookie(MODALS.COOKIES_POLICY, cookieVal, 2 * 365, domain, path);
    loadCookiesPolicyDependentScripts(cookieVal);
    loadCDPModulesDepentCookies(cookieVal);
    setGDPRCookie(cookieVal);
    removeModal();
  };

  const loadCookiesPolicyDependentScripts = (cookieVal) => {
    if (hasBazaarVoice) {
      const eciMonitorization = UtilsCookies.getCookieValue('eci_monitoriz');
      if (!eciMonitorization && (cookieVal == '1' || cookieVal?.charAt(4) == '1')) {
        ScriptUtils.addScriptFromUrl(bazaarVoiceSEO);
      }
    }
  };

  const methodForAdobeEvent = () => {    
    UtilsPlacements.loadCDPfunctions(groupedPlacements.value);
    emit('cookiesAccepted', true);
  };

  const loadCDPModulesDepentCookies = (cookieVal) => {    
    const customizationCookie = cookieVal?.charAt(2);
    if(!customizationCookie) return;

    if(customizationCookie == '1') {
      document.addEventListener('AdobeSDKLoaded', methodForAdobeEvent);
    } else if(customizationCookie == '0') {      
      window.localStorage.setItem('kxelcorteinglessa_segs', DEFAULT_SEGMENT);
      UtilsPlacements.loadCDPfunctions(groupedPlacements.value);
    }
    else {
      window.localStorage.setItem('kxelcorteinglessa_segs', DEFAULT_SEGMENT);
      document.addEventListener('AdobeSDKLoaded', () => {
        UtilsPlacements.loadCDPfunctions(groupedPlacements.value);
        emit('cookiesAccepted', false);
      });
    }
    UtilsPlacements.removeHiddenByCDPSegmentsAutofind();
    UtilsAnalytics.sendReloadEvent();
    UtilsAnalytics.resendUserEvent();
  };

  const handleDesigners = () => removeModal();

  onMounted(() => {
    if (!UtilsCookies.existsCookie(MODALS.COOKIES_POLICY) && !hasQueryNoCookiesModal) {
      addModal(MODALS.COOKIES_POLICY);
    }
    addModal(MODALS.DESIGNERS_POPUP);
  });

  onUnmounted(() => {
    document.removeEventListener('AdobeSDKLoaded', methodForAdobeEvent);
  })
</script>
